/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React, { useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import {
  Button,
  Detail,
  VisualizationAttr,
  Modal,
  Spinner,
} from 'commons/components'

import * as Layouts from 'commons/layouts'

const DetailTarikDana = ({ data }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  //   {
  //     "data": {
  //         "date": "2024-06-15",
  //         "amount": 470000,
  //         "specialMoneyTransferSenderCountry": "",
  //         "specialMoneyTransferSenderAddress": "",
  //         "agentMoneyTransferId": "",
  //         "idProgram": "77a04a95-bf2f-41a9-afa7-9a2abc1ce801",
  //         "internationalMoneyTransferSourceCountry": "",
  //         "internationalMoneyTransferDestinationCountry": "",
  //         "description": "Banjir kali ciliwung jakarta",
  //         "vendorName": "Flip",
  //         "disbursementMethod": "Money Transfer",
  //         "internationalMoneyTransferAmountInSenderCountry": "",
  //         "internationalMoneyTransferExchangeRate": "",
  //         "specialMoneyTransferSenderJob": "",
  //         "specialMoneyTransferDirection": "",
  //         "disbursementId": "166042",
  //         "agentMoneyTransferDirection": "",
  //         "id": "61db39f0-2560-4daa-9e86-ee8970b3ffc0",
  //         "specialMoneyTransferSenderName": "",
  //         "internationalMoneyTransferFee": "",
  //         "internationalMoneyTransferBeneficiaryCurrencyCode": "",
  //         "status": "PENDING"
  //     }
  // }

  const allItemsAttrs = [
    {
      id: 'disbursementId',
      condition: '',
      label: 'ID',
      featureName: 'disbursementId',
    },
    { id: 'date', condition: '', label: 'Date', featureName: 'date' },
    { id: 'amount', condition: '', label: 'Amount', featureName: 'amount' },
    {
      id: 'disbursementMethod',
      condition: '',
      label: 'Disbursement Method',
      featureName: 'disbursementMethod',
    },
    {
      id: 'description',
      condition: '',
      label: 'Description',
      featureName: 'description',
    },
    {
      id: 'idProgram',
      condition: '',
      label: 'ID Program',
      featureName: 'idProgram',
    },
    {
      id: 'vendorName',
      condition: '',
      label: 'Vendor Name',
      featureName: 'vendorName',
    },
    { id: 'status', condition: '', label: 'Status', featureName: 'status' },
    {
      id: 'specialMoneyTransferSenderCountry',
      condition: '',
      label: 'Special SenderCountry',
      featureName: 'specialMoneyTransferSenderCountry',
    },
    {
      id: 'specialMoneyTransferSenderAddress',
      condition: '',
      label: 'Special SenderAddress',
      featureName: 'specialMoneyTransferSenderAddress',
    },
    {
      id: 'agentMoneyTransferId',
      condition: '',
      label: 'agent Id',
      featureName: 'agentMoneyTransferId',
    },
    {
      id: 'internationalMoneyTransferSourceCountry',
      condition: '',
      label: 'International SourceCountry',
      featureName: 'internationalMoneyTransferSourceCountry',
    },
    {
      id: 'internationalMoneyTransferDestinationCountry',
      condition: '',
      label: 'International DestinationCountry',
      featureName: 'internationalMoneyTransferDestinationCountry',
    },
    {
      id: 'internationalMoneyTransferAmountInSenderCountry',
      condition: '',
      label: 'International Amount In SenderCountry',
      featureName: 'internationalMoneyTransferAmountInSenderCountry',
    },
    {
      id: 'internationalMoneyTransferExchangeRate',
      condition: '',
      label: 'International ExchangeRate',
      featureName: 'internationalMoneyTransferExchangeRate',
    },
    {
      id: 'internationalMoneyTransferFee',
      condition: '',
      label: 'International Fee',
      featureName: 'internationalMoneyTransferFee',
    },
    {
      id: 'internationalMoneyTransferBeneficiaryCurrencyCode',
      condition: '',
      label: 'International BeneficiaryCurrencyCode',
      featureName: 'internationalMoneyTransferBeneficiaryCurrencyCode',
    },
    {
      id: 'specialMoneyTransferSenderJob',
      condition: '',
      label: 'Special SenderJob',
      featureName: 'specialMoneyTransferSenderJob',
    },
    {
      id: 'specialMoneyTransferDirection',
      condition: '',
      label: 'Special Direction',
      featureName: 'specialMoneyTransferDirection',
    },
    {
      id: 'agentMoneyTransferDirection',
      condition: '',
      label: 'Agent Direction',
      featureName: 'agentMoneyTransferDirection',
    },
    {
      id: 'specialMoneyTransferSenderName',
      condition: '',
      label: 'Special SenderName',
      featureName: 'specialMoneyTransferSenderName',
    },
  ]

  // Filter the itemsAttrs array based on the existence of data
  const filteredItemsAttrs = allItemsAttrs.filter(
    attr => data[attr.featureName] !== ''
  )

  return (
    <Layouts.DetailComponentLayout
      item={data}
      itemsAttrs={filteredItemsAttrs}
      itemsEvents={[]}
      itemsModals={[]}
    />
  )
}

export default DetailTarikDana
