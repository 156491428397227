/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Modal } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { isMobile } from 'commons/utils/responsive'

import * as Layouts from 'commons/layouts'

const TarikDanaTable = ({ viaPGListElement }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()
  const detail = async viaPaymentGatewayItem => {
    isMobile() && navigate(`/disbursement/detail/${viaPaymentGatewayItem.id}`)
  }

  // {
  //     "data": {
  //         "date": "2024-06-15",
  //         "amount": 450000,
  //         "specialMoneyTransferSenderCountry": "100156",
  //         "specialMoneyTransferSenderAddress": "alamat",
  //         "agentMoneyTransferId": "",
  //         "idProgram": "77a04a95-bf2f-41a9-afa7-9a2abc1ce801",
  //         "internationalMoneyTransferSourceCountry": "",
  //         "internationalMoneyTransferDestinationCountry": "",
  //         "vendorName": "Flip",
  //         "internationalMoneyTransferAmountInSenderCountry": "",
  //         "internationalMoneyTransferExchangeRate": "",
  //         "specialMoneyTransferSenderJob": "entrepreneur",
  //         "specialMoneyTransferDirection": "DOMESTIC_SPECIAL_TRANSFER",
  //         "disbursementId": "166041",
  //         "agentMoneyTransferDirection": "",
  //         "id": "cd38bf74-e858-4bbe-8e72-08f7bb19d346",
  //         "specialMoneyTransferSenderName": "radit",
  //         "internationalMoneyTransferFee": "",
  //         "internationalMoneyTransferBeneficiaryCurrencyCode": "",
  //         "status": "PENDING"
  //     }
  // }

  // {
  //     "data": {
  //         "date": "2024-06-15",
  //         "amount": 450000,
  //         "specialMoneyTransferSenderCountry": "",
  //         "specialMoneyTransferSenderAddress": "",
  //         "agentMoneyTransferId": "",
  //         "idProgram": "77a04a95-bf2f-41a9-afa7-9a2abc1ce801",
  //         "internationalMoneyTransferSourceCountry": "",
  //         "internationalMoneyTransferDestinationCountry": "",
  //         "description": "Banjir kali ciliwung jakarta",
  //         "vendorName": "Flip",
  //         "internationalMoneyTransferAmountInSenderCountry": "",
  //         "internationalMoneyTransferExchangeRate": "",
  //         "specialMoneyTransferSenderJob": "",
  //         "specialMoneyTransferDirection": "",
  //         "disbursementId": "166040",
  //         "agentMoneyTransferDirection": "",
  //         "id": "e73144cd-402b-4cf7-8e76-a54c5e6e1ca8",
  //         "specialMoneyTransferSenderName": "",
  //         "internationalMoneyTransferFee": "",
  //         "internationalMoneyTransferBeneficiaryCurrencyCode": "",
  //         "status": "PENDING"
  //     }
  // }

  return (
    <Layouts.ListComponentTableLayout
      items={[viaPGListElement]}
      detail={detail}
      itemsAttrs={[
        {
          id: 'disbursementId',
          condition: 'isHeading',
          label: 'ID',
          featureName: 'disbursementId',
        },
        {
          id: 'disbursementMethod',
          condition: 'isHeading',
          label: 'Disbursement Method',
          featureName: 'disbursementMethod',
        },
        {
          id: 'status',
          condition: 'isHeading',
          label: 'Status',
          featureName: 'status',
        },
        {
          id: 'amount',
          condition: 'isHeading',
          label: 'Amount',
          featureName: 'amount',
        },
      ]}
      itemsEvents={viaPaymentGatewayItem => [
        <Link to={`/disbursement/detail/${viaPaymentGatewayItem.id}`}>
          <Button variant="primary">Detail</Button>
        </Link>,
      ]}
      itemsModals={viaPaymentGatewayItem => []}
    />
  )
}

export default TarikDanaTable
